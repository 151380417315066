// src/components/Navbar.js

import React from 'react';

const Navbar = () => {
    return (
        <div className="navbar">
            <img src="logo.png" alt="Logo" className="logo" />
            <img src="logo-phone.png" alt="Logo" className="logo-phone" />
            <ul className="nav-links">
                <li><a href="#home">Home</a></li>
                <li>/</li>
                <li className="nav-subs-title">Pitch Deck
                    <div className='nav-subs'>
                        <div className="nav-sub">
                            <a href="/Ternary Daphne Delta Zero Pitch Deck.pdf" target='_blank' rel='noopener noreferrer'>EN Version</a>
                        </div>
                        <div className="nav-sub">
                            <a href="/Ternary Daphne Delta Zero Picth Deck (CN).pdf" target='_blank' rel='noopener noreferrer'>CN Version</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;
