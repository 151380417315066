// src/components/LandingPage.js

import React from 'react';

const LandingPage = () => {
    return (
        <div className="landing">
            <div className="row1">
                <div className='row1-left'>
                    <h1>Daphne Capital</h1>
                    <p>We are a boutique trading firm that focuses on automated delta neutral strategies.</p>
                    <p>We provide portfolio management consulting and liquidity provision services.</p>
                    <p>We also actively pursue early stage investment opportunities.</p>
                    <div className="landing-buttons">
                        <button className="landing-button">info@daphne.capital</button>
                        <button className="landing-button-2">
                            <img src="button-2.png" alt="Logo" className="landing-button-2-img" />
                        </button>
                        <a className="mobile-pitch-deck-link" href="/Ternary Daphne Delta Zero Pitch Deck.pdf" target='_blank' rel='noopener noreferrer'>Pitch Deck</a>
                        <a className="mobile-pitch-deck-link" href="/Ternary Daphne Delta Zero Picth Deck (CN).pdf" target='_blank' rel='noopener noreferrer'>Pitch Deck (CN)</a>
                    </div>
                </div>
                <img src="cover.png" alt="cover img" className="landing-cover" />
                <img src="cover-phone.png" alt="cover img" className="landing-cover-phone row1-right" />
            </div>
            <img src="cards.png" alt="cards" className="landing-cards" />
            <img src="cards-phone.png" alt="cards" className="landing-cards-phone" />
        </div>
    );
}

export default LandingPage;
